 #contribution-table {
    border-collapse: separate;
    border-spacing: 2px;
    width: 100%;
}

 #contribution-table td {
    height: 10px;
    width: 10px;
    border: 1px solid rgba(238, 238, 238, 0.16);
    border-radius: 4px;
}